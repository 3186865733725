.app {
  min-height: 100vh;
  color: white;
  font-family: "Inter", sans-serif;
  padding: 30px;
  display: flex;
  flex-direction: column;
}

table,
th,
td {
  border: 1px solid white;
  border-collapse: collapse;
}

.header {
  text-align: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.logo {
  margin: 0 5px 0 0;
  object-fit: contain;
}

/* .header.h1 {
  flex: 1 1 auto;
} */

nav {
  display: flex;
  justify-content: center;
  min-height: 5vh;
}

.nav-links {
  width: 50%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-self: center;
  list-style: none;
}

.status {
  text-align: center;
  min-height: 2vh;
}

.comparisoninner {
  flex-flow: row wrap;
  justify-content: center;
}

.comparisonchild1 {
  flex: 1 1.5 544px;
  align-self: center;
}

.comparisonchild2 {
  flex: 1.5 1 544px;
  align-self: center;
}

.pricesmain {
  white-space: pre-line;
}

.priceschild1,
.priceschild2,
.priceschild3 {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  text-align: center;
}

.price {
  background: #2c2c2c;
  border-radius: 15px;
  border-color: black;
  /* width: 200px; */
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 10px;
  text-shadow: 1px 1px 5px black;
}

.price#agile {
  background: #b968cc;
}

.price#go {
  background: #65be2e;
}

.price#gofaster {
  background: #006633;
}

.tariffname {
  font-size: 13px;
  margin-bottom: 5px;
}

@media only screen and (max-width: 858px) {
  .tariffname {
    font-size: 11px;
  }

  .price {
    font-size: 12px;
  }
}

.footer {
  padding-top: 10px;
  text-align: center;
  margin-top: auto;
}

.inputs {
  text-align: center;
}

input[type="text"] {
  color: white;
  background: #2c2c2c;
  border-radius: 18px;
  border-color: black;
  padding: 20px;
  width: 300px;
  height: 15px;
  font-family: inherit;
  outline: none;
}

input[type="date"],
input[type="month"],
input[type="number"] {
  color: white;
  background: #2c2c2c;
  border-radius: 18px;
  border-color: black;
  padding: 20px;
  width: 200px;
  height: 15px;
  font-family: inherit;
  text-align: center;
  outline: none;
}

input[type="date"],
input[type="month"]::-webkit-calendar-picker-indicator {
  /* color: rgba(0, 0, 0, 0); */
  /* color: white; */
  /* opacity: 1; */
  /* background: url(./img/calendar.png) no-repeat; */
  /* width: 20px; */
  /* height: 20px; */
  /* display: none; */
}

/* input[type="date"]::-webkit-calendar-picker-indicator:hover {
  color: white;
  background-color: transparent;
  cursor: pointer;
} */

/* input[type="date"]::-webkit-inner-spin-button {
   -webkit-appearance: none;
  display: none; 
} */

input[type="submit"] {
  color: white;
  background: #2c2c2c;
  border-radius: 18px;
  border-color: black;
  width: 110px;
  height: 50px;
  font-family: inherit;
  outline: none;
}

input[type="checkbox"] {
  width: 2em;
  height: 2em;
  background-color: #2c2c2c;
  border-radius: 18px;
  vertical-align: middle;
  border: black;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  margin-top: 0.25%;
  margin-right: 0.25%;
}

input[type="checkbox"]:checked {
  background-color: gray;
}

input:hover {
  background: #3f3f3f;
}

button {
  color: white;
  background: #2c2c2c;
  border-radius: 50px;
  border-color: black;
  width: fit-content;
  padding-left: 20px;
  padding-right: 20px;
  height: 50px;
  font-family: inherit;
  outline: none;
}

button:hover {
  background: #3f3f3f;
}

.monthsTitle {
  padding-left: 10px;
  padding-top: 5px;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.checkbox-tools.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: grey;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

a:link {
  color: white;
  text-decoration: underline;
}

a:visited {
  color: white;
  text-decoration: underline;
}

.checkbox-tools[type="checkbox"]:checked,
.checkbox-tools[type="checkbox"]:not(:checked),
.checkbox-tools[type="radio"]:checked,
.checkbox-tools[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
  width: 0;
  height: 0;
  visibility: hidden;
}

.checkbox-tools:checked + label,
.checkbox-tools:not(:checked) + label {
  position: relative;
  display: inline-block;
  padding: 20px;
  width: 110px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  margin: 0 auto;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  text-align: center;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  text-transform: uppercase;
  color: #ffffff;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}
.checkbox-tools:not(:checked) + label {
  background-color: #353746;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.checkbox-tools:checked + label {
  background-color: transparent;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.checkbox-tools:not(:checked) + label:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.checkbox-tools:checked + label::before,
.checkbox-tools:not(:checked) + label::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-image: linear-gradient(298deg, #2c4fda, #37cbf8);
  z-index: -1;
}
.checkbox-tools:checked + label .uil,
.checkbox-tools:not(:checked) + label .uil {
  font-size: 24px;
  line-height: 24px;
  display: block;
  padding-bottom: 10px;
}

.checkbox:checked
  ~ .section
  .container
  .row
  .col-12
  .checkbox-tools:not(:checked)
  + label {
  background-color: #f0eff3;
  color: #1f2029;
  box-shadow: 0 1x 4px 0 rgba(0, 0, 0, 0.05);
}
